/* global UC_UI */

import { addEventOnce } from "../../utils/events/events";
import { onReady } from "../../utils/events/onReady";
import {
    disableScrollLock,
    enableScrollLock
} from "../../utils/dom/scrollLock";

export class InformationModal {
    /**
     * Internal placeholder for DOM-objects
     *
     * @type {object}
     * @ignore
     */
    dom = {
        container: undefined
    };

    /**
     *
     * @param {Element} domReference - The element to work from
     */
    constructor(domReference) {
        this.dom.container = domReference;
        this.dom.classList = {
            hiddenClass: "hidden",
            informationModal: "information-modal",
            InformationModalActive: "information-modal--active",
            InformationModalClose: "information-modal__close"
        };
        onReady(() => this.init(domReference));
    }
    cookieConsent() {
        let consent = false;
        if (!window.UC_UI || !UC_UI.isInitialized()) {
            window.addEventListener("UC_UI_INITIALIZED", () => {
                if (UC_UI.areAllConsentsAccepted()) {
                    consent = true;
                }
            });
        } else if (UC_UI.areAllConsentsAccepted()) {
            consent = true;
        }
        return consent;
    }
    setCookie(name) {
        const date = new Date();
        date.setTime(date.getTime() + 1 * 3600 * 1000);
        let expires = "expires=" + date.toUTCString();
        document.cookie = name + "=true;" + expires + ";path=/";
    }
    getCookie(name) {
        const match = document.cookie.match(
            new RegExp("(^| )" + name + "=([^;]+)")
        );
        if (match) return match[2];
    }
    showInformationModal(name) {
        this.dom.container.classList.add(
            this.dom.classList.InformationModalActive
        );
        if (this.cookieConsent()) {
            this.setCookie(name);
        }
        enableScrollLock();
    }
    hideInformationModal() {
        this.dom.container.classList.remove(
            this.dom.classList.InformationModalActive
        );

        disableScrollLock();
    }
    init(domReference) {
        const self = this;
        const close = document.querySelector(
            `.${self.dom.classList.InformationModalClose}`
        );
        const cookieName = domReference.dataset.id;

        if (cookieName) {
            //check if there isn't a cookie to show the popup
            let iscookieExists = this.getCookie(cookieName);
            if (!iscookieExists) {
                this.showInformationModal(cookieName);
            }
        }
        addEventOnce(close, "click", () => {
            this.hideInformationModal();
        });
    }
}
