// If you want to use React instead of vanilla modules, use the reactBootstrapper,
// the same rules and guidelines apply for this bootstrapper as they do for the bootstrapper and asyncBootstrapper (split your code responsibly).
// The reactBootstrapper supports both static and dynamic imports - which is why there is no asyncReactBootstrapper
// import { reactBootstrapper } from "./utils/react/reactBootstrapper";

import "./utils/globals";
import { onReady } from "./utils/events/onReady";
import {
    onWindowResize,
    currentBreakpoint,
    breakpointIndex
} from "./utils/events/onWindowResize";
import { enableScrollLock } from "./utils/dom/scrollLock";
import {
    getScrollBarWidth,
    getNavHeight,
    getInPageNavigationHeight,
    getInPageNavigationOffset,
    getInPageNavigationScrollHeight
} from "./utils/dom/cssProps";
import { isElementInViewport } from "./utils/dom/elementProperties";
import { lazyLoad } from "./utils/dom/lazyLoad";
import { Navigation } from "./components/navigation/navigation";
import { Modal } from "./components/modal/modal";
import { ExternalLink } from "./components/external-link/external-link";
import { Accordion } from "./components/accordion/accordion";
import { References } from "./components/references/references";
import { RibbonImage } from "./components/ribbon-image/ribbon-image";
import { Hero } from "./components/hero/hero";
import { Overlay } from "./components/overlay/overlay";
import { InformationModal } from "./components/information-modal/information-modal";
import { Carousel } from "./components/multi-column-carousel/multi-column-carousel";
import { CarouselVideo } from "./components/multi-column-carousel/multi-column-carousel-video";
import { Video } from "./components/video/video";
import { ScrollTop } from "./components/scroll-top/scroll-top";
import { InPageNavigation } from "./components/in-page-navigation/in-page-navigation";
import { GatedContent } from "./components/gated-content/gated-content";
import { bootstrapper } from "./utils/bootstrapper";
import { throttle } from "./utils/dom/throttle";
import { scrollDirection } from "./utils/events/scrollDirection";
import { ThreeQ } from "./components/threeq/3q";

export const modules = {
    modal: Modal,
    "external-link": ExternalLink,
    navigation: Navigation,
    accordion: Accordion,
    references: References,
    "ribbon-image": RibbonImage,
    hero: Hero,
    video: Video,
    overlay: Overlay,
    "scroll-top": ScrollTop,
    "in-page-navigation": InPageNavigation,
    "gated-content": GatedContent,
    carousel: Carousel,
    "carousel-video": CarouselVideo,
    "information-modal": InformationModal,
    threeq: ThreeQ
};

bootstrapper(modules);

// You can use the following code to listen for when the styles on the page are lazy-loaded. This is cool for waiting for
// styles to be rendered and ready (if you need them for calculating sizes and similar), but since everything is loaded
// asynchronously, you can't know the load order, so the CSS might be loaded before the JS is. Your code MUST assume that
// the CSS has *already* been loaded once the JS runs.
// This function is especially helpful for slow browsers (IE) and slow/unstable connections.
//
// window.akqa = window.akqa || {};
// window.akqa.stylesRendered = () => {
//     // Run your repaint callbacks here. Consider using a throttle/debounce, and
//     // remember to run the function at load-time if the code here is important
//     // for your layout (pro-tip: it probably shouldn't be).
// };

onReady(() => {
    // init css Props
    getScrollBarWidth();
    getNavHeight();
    getInPageNavigationHeight();
    getInPageNavigationOffset();
    getInPageNavigationScrollHeight();

    onWindowResize(getNavHeight);
    onWindowResize(getInPageNavigationHeight);
    onWindowResize(getInPageNavigationOffset);

    /* remove js class dymamically */
    (() => {
        document.documentElement.classList.remove("no-js");
    })();

    /* gated content */
    const gatedContent = document.querySelectorAll(".gated-content");
    if (gatedContent && gatedContent.length) {
        enableScrollLock();
    }

    /* lazyload images */
    (() => {
        lazyLoad(".lazy");
        const attachScrollLoadEvent = () => {
            const lazyLoadBackgroundImage = document.querySelectorAll(
                `[data-src]`
            );
            if (lazyLoadBackgroundImage.length) {
                lazyLoadBackgroundImage.forEach(item => {
                    if (isElementInViewport(item)) {
                        const src = item.dataset.src;
                        item.classList.add("background-image-loaded");
                        item.setAttribute(
                            "style",
                            'background-image: url("' + src + '")'
                        );
                    }
                });
            }
        };
        attachScrollLoadEvent();
        document.addEventListener("scroll", () => {
            attachScrollLoadEvent();
        });
    })();

    /* scroll events */
    (() => {
        const elem = document.body;
        const scrollClass = "scroll--active";
        const scrollUpClass = "scroll--up";
        const scrollDownClass = "scroll--down";
        const inPageNavigationClass = "in-page-navigation--active";
        const interval = 3000;
        const scrollYOffset = 200;
        const inPageNavigation = document.querySelector(`.in-page-navigation`);
        const inPageNavigationInner = document.querySelector(
            `.in-page-navigation__inner`
        );
        const inPageNavigationOffsetTop = inPageNavigation?.offsetTop;
        const isMobile = currentBreakpoint < breakpointIndex.lg;
        if (window.scrollY > scrollYOffset) {
            elem.classList.add(scrollClass);
        }
        document.addEventListener("scroll", () => {
            if (
                inPageNavigation &&
                window.scrollY > inPageNavigationOffsetTop &&
                inPageNavigationInner?.offsetHeight
            ) {
                throttle(elem.classList.add(inPageNavigationClass), interval);
            } else {
                throttle(
                    elem.classList.remove(inPageNavigationClass),
                    interval
                );
            }
            if (window.scrollY > scrollYOffset) {
                throttle(elem.classList.add(scrollClass), interval);
            } else {
                throttle(elem.classList.remove(scrollClass), interval);
            }
            if (isMobile) {
                getInPageNavigationScrollHeight();
            }
        });
        scrollDirection((scrollPos, previousScrollPos) => {
            if (previousScrollPos > scrollPos) {
                elem.classList.remove(scrollDownClass);
                elem.classList.add(scrollUpClass);
            } else {
                elem.classList.remove(scrollUpClass);
                elem.classList.add(scrollDownClass);
            }
        });
    })();

    /* set alt text */
    (() => {
        const images = document.querySelectorAll("img");
        images.forEach(item => {
            const altText = item.getAttribute("alt");
            if (!altText) {
                item.setAttribute("alt", "Eisai Pro");
            }
        });
    })();
}, 150);
